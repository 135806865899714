import { ErrorService } from './../../../common/error.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'szz-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {
  public message: string;
  public errorCode: number;
  constructor(private errorService: ErrorService) {
    this.errorCode = this.errorService.getErrorCode();
    this.message = this.errorService.getErrorMessage();
  }

  ngOnInit() {
  }

}
