import { Router, Event, NavigationError } from "@angular/router";
import { Injectable, Injector, NgZone } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { SubjectSubscriber, Subject } from "rxjs/internal/Subject";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class BackButtonStateService {
  private showButtonData: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);
  showButton: Observable<boolean>;

  constructor() {
    this.showButton = this.showButtonData.asObservable();
  }

  updateButtonState(newState: boolean) {
    this.showButtonData.next(newState);
  }
}
