import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { LoggedUserService } from "src/app/common/logged.user.service";
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";
import * as DateFns from "date-fns";
import {
  ValidityForPolicyViewModel, ValidityForClaimViewModel
} from "src/app/data/api/models";
import {
  DriversLicenseService
} from "src/app/data/api/services";
import { debug } from 'util';
import { tap } from 'rxjs/operators';

@Component({
  selector: "szz-rules-new",
  templateUrl: "./search-drivers-licence.component.html",
  styleUrls: ["./search-drivers-licence.component.scss"]
})
export class SearchDriversLicenceComponent implements OnInit {
  form: FormGroup;
  policyResult$: Observable<ValidityForPolicyViewModel>;
  searchReason: Observable<string>;
  canSearchForPolicy: boolean = true;
  canSearchForClaimFile: boolean = true;
  searchInProgress: boolean = false;

  searchReasons = [
    { text: 'Sklepanje polic', value: "1", disabled: true },
    { text: 'Reševanje škodnega primera', value: "2", disabled: true }
  ];

  categories = [
    'AM',
    'A1',
    'A2',
    'A',
    'B1',
    'B',
    'BE',
    'C1',
    'C1E',
    'C',
    'CE',
    'D1',
    'D1E',
    'D',
    'DE',
    'F',
    'G'
  ];

  claimResolutionResult$: Observable<ValidityForClaimViewModel>;
  checkDate: Date;
  category: any;

  constructor(
    private driversLicenseServiceApi: DriversLicenseService,
    private loggedUserService: LoggedUserService,
  ) { }

  ngOnInit() {
    this.canSearchForPolicy = this.loggedUserService.vdCanSearcPolicy();
    this.canSearchForClaimFile = this.loggedUserService.vdCanSearcForClaimFile();
    this.form = new FormGroup({
      personUniqueNumber: new FormControl(""),
      searchReason: new FormControl(this.canSearchForPolicy ? "1" : "2", Validators.required),
      serialNumber: new FormControl(""),
      date: new FormControl("", Validators.required),
      category: new FormControl("", Validators.required),
      referenceId: new FormControl("", Validators.required)
    }, this.justOneValid(Validators.required, ['personUniqueNumber', 'serialNumber']));
    this.disableFields();
  }

  justOneValid = (validator: ValidatorFn, controls: string[] = null) => (
    group: FormGroup,
  ): ValidationErrors | null => {
    if (!controls) {
      controls = Object.keys(group.controls)
    }
    const numberOfValidInputs = group && group.controls && controls.filter(x => !validator(group.controls[x])).length;

    return numberOfValidInputs == 1 ? null : {
      justOneValid: true,
    };
  };

  readonlyMode() {
    return (!this.canSearchForClaimFile && !this.canSearchForPolicy);
  }


  private disableFields() {
    this.searchReasons[0].disabled = !this.canSearchForPolicy;
    this.searchReasons[1].disabled = !this.canSearchForClaimFile;

    if (this.readonlyMode()) {
      for (var control in this.form.controls) {
        this.form.controls[control].disable();
      }
    }
  }

  submit() {
    if (this.readonlyMode() || this.searchInProgress) {
      return;
    }
    this.form.markAllAsTouched();
    this.form.markAsDirty();
    if (this.form.valid) {

      this.searchInProgress = true;
      this.claimResolutionResult$ = null;
      this.policyResult$ = null;
      if (this.form.getRawValue().searchReason == "1") {

        this.policyResult$ = this.driversLicenseServiceApi.SearchPolicy({
          uniqueIdentificationNumber: this.form.getRawValue().personUniqueNumber,
          category: this.form.getRawValue().category,
          date: DateFns.startOfDay(this.form.getRawValue().date).toISOString(),
          serialNumber: this.form.getRawValue().serialNumber,
          referenceId: this.form.getRawValue().referenceId
        }).pipe(
          tap(x=>{
          this.searchInProgress=false;
        }));

      } else {
        
        this.claimResolutionResult$ = this.driversLicenseServiceApi.SearchForClaimFile({
          uniqueIdentificationNumber: this.form.getRawValue().personUniqueNumber,
          category: this.form.getRawValue().category,
          date: DateFns.startOfDay(this.form.getRawValue().date).toISOString(),
          serialNumber: this.form.getRawValue().serialNumber,
          referenceId: this.form.getRawValue().referenceId
        }).pipe(
          tap(x=>{
          this.searchInProgress=false;
        }));
      }
      this.checkDate = DateFns.startOfDay(this.form.getRawValue().date);
      this.category = this.form.getRawValue().category;
    }
  }
}
