import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import {
  BsDropdownModule,
  CollapseModule,
  ModalModule,
  TooltipModule,
  BsDatepickerModule,
  TypeaheadModule,
  TabsModule
} from "ngx-bootstrap";

@NgModule({
  imports: [
    CommonModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    TimepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    TabsModule.forRoot()
  ],
  exports: [
    BsDropdownModule,
    BsDatepickerModule,
    TooltipModule,
    ModalModule,
    TimepickerModule,
    CollapseModule,
    TypeaheadModule,
    TabsModule
  ]
})
export class BootstrapModule {}
