import { NotFoundComponent } from "./pages/not-found/not-found/not-found.component";
import { Routes } from "@angular/router";
import { DashboardPageComponent } from "./pages/dashboard/dashboard-page/dashboard-page.component";
import { SearchDriversLicenceComponent } from "./pages/search-drivers-licence-page/search-drivers-licence/search-drivers-licence.component";

export const ROUTES: Routes = [
  {
    path: "",
    component: SearchDriversLicenceComponent,
    data: {
      title: "search",
      breadcrumb: "dashboard"
    }
  },
  {
    path: "**",
    redirectTo: "dashboard",
    data: { title: "dashboard", breadcrumb: "dashboard" }
  },
  {
    path: "search",
    component: SearchDriversLicenceComponent
  },
  {
    path: "error",
    data: {
      title: "notfound",
      breadcrumb: "notfound"
    },
    component: NotFoundComponent
  }
];
