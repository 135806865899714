import { Router, Event, NavigationError } from '@angular/router';
import { Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private injector: Injector,
    private router: Router,
    private zone: NgZone
  ) { }

  getErrorMessage() {
    const state = this.router.getCurrentNavigation().extras.state;
    if (typeof(state) !== 'undefined' && state !== null) {
      return state.message || 'Page not found.';
    }
    return 'Page not found.';
  }

  getErrorCode() {
    const state = this.router.getCurrentNavigation().extras.state;
    if (typeof(state) !== 'undefined' && state !== null) {
      return state.errorCode || 404;
    }
    return 404;
  }

  navigeteToErrorPage(errorMessage: string, code: number): void {
    this.zone.run(() => {
      this.router.navigate(['/error'], {state: {message: errorMessage, errorCode: code} });
    });
  }

  getClientErrorMessage(error: Error): string {
    return error.message ?
           error.message :
           error.toString();
  }

  getServerErrorMessage(error: HttpErrorResponse): string {
    return !navigator.onLine ? 'No Internet Connection' :
           error.error.messages instanceof Array ? error.error.messages.join(' - ') :
           error.error;
  }
}
