import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { CommonModule, NgSwitchCase } from "@angular/common";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CollapseModule } from "ngx-bootstrap";
import { Ng5SliderModule } from "ng5-slider";
import { TabsModule } from "ngx-bootstrap/tabs";

@NgModule({
  declarations: [
  ],
  exports: [
    DragDropModule,
    TabsModule,
  ],
  imports: [
    CommonModule,
    CollapseModule,
    DragDropModule,
    RouterModule,
    ReactiveFormsModule,
    MatListModule,
    MatInputModule,
    Ng5SliderModule,
    FormsModule,
    TabsModule
  ]
})
export class ComponentsModule {}
