import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatListModule } from "@angular/material/list";
import { MatInputModule } from "@angular/material/input";
import { SearchDriversLicenceComponent } from "./search-drivers-licence/search-drivers-licence.component";
import { ComponentsModule } from "src/app/components/components.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BootstrapModule } from "../../common/bootstrap.module";
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  declarations: [SearchDriversLicenceComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    MatListModule,
    MatRadioModule,
    MatInputModule,
    BrowserAnimationsModule,
    BootstrapModule
  ],
  exports: [SearchDriversLicenceComponent, ComponentsModule]
})
export class SearchDriversLicenceModule { }
