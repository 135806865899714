import { Component, OnInit } from "@angular/core";
import { ProfileService } from "src/app/data/api/services";
import { tap } from "rxjs/operators";
import { ProfileViewModel } from "src/app/data/api/models";
import { LoggedUserService } from "src/app/common/logged.user.service";
import { Location } from "@angular/common";
import { BackButtonStateService } from "src/app/common/back-button-state.service";

@Component({
  selector: "app-container",
  templateUrl: "./container.component.html",
  styleUrls: ["./container.component.scss"]
})
export class ContainerComponent implements OnInit {
  collapsed: boolean = true;
  model: ProfileViewModel = null;
  showBackButton: boolean = false;
  window;

  constructor(
    private profileService: ProfileService,
    private location: Location,
    private loggedUserService: LoggedUserService,
    private backButtonStateService: BackButtonStateService
  ) {
    this.window = window;
  }

  ngOnInit() {
     this.profileService
       .Self({})
       .pipe(
         tap(x => {
           this.model = x;
           this.model.fullName = this.model.fullName.trim() || "Name Undefined";
           this.loggedUserService.setIsAdmin(this.model.isAdmin);
           this.loggedUserService.setVdCanSearcPolicy(this.model.vdSearchPolicy);
           this.loggedUserService.setCanSearcForClaimFile(this.model.vdSearchForClaimFile);

         })
       )
       .subscribe();

    this.backButtonStateService.showButton
      .pipe(
        tap(x => {
          this.showBackButton = x;
        })
      )
      .subscribe();
  }

  goBack() {
    this.location.back();
  }
}
