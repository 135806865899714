/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ValidityForPolicyViewModel } from '../models/validity-for-policy-view-model';
import { ValidityForClaimViewModel } from '../models/validity-for-claim-view-model';
@Injectable({
  providedIn: 'root',
})
class DriversLicenseService extends __BaseService {
  static readonly SearchPolicyPath = '/driverslicense/searchpolicy';
  static readonly SearchForClaimFilePath = '/driverslicense/searchforclaimfile';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DriversLicenseService.SearchPolicyParams` containing the following parameters:
   *
   * - `uniqueIdentificationNumber`:
   *
   * - `serialNumber`:
   *
   * - `referenceId`:
   *
   * - `date`:
   *
   * - `category`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return The results are returned.
   */
  SearchPolicyResponse(params: DriversLicenseService.SearchPolicyParams): __Observable<__StrictHttpResponse<ValidityForPolicyViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.uniqueIdentificationNumber != null) __params = __params.set('uniqueIdentificationNumber', params.uniqueIdentificationNumber.toString());
    if (params.serialNumber != null) __params = __params.set('serialNumber', params.serialNumber.toString());
    if (params.referenceId != null) __params = __params.set('referenceId', params.referenceId.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    if (params.category != null) __params = __params.set('category', params.category.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/driverslicense/searchpolicy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ValidityForPolicyViewModel>;
      })
    );
  }
  /**
   * @param params The `DriversLicenseService.SearchPolicyParams` containing the following parameters:
   *
   * - `uniqueIdentificationNumber`:
   *
   * - `serialNumber`:
   *
   * - `referenceId`:
   *
   * - `date`:
   *
   * - `category`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return The results are returned.
   */
  SearchPolicy(params: DriversLicenseService.SearchPolicyParams): __Observable<ValidityForPolicyViewModel> {
    return this.SearchPolicyResponse(params).pipe(
      __map(_r => _r.body as ValidityForPolicyViewModel)
    );
  }

  /**
   * @param params The `DriversLicenseService.SearchForClaimFileParams` containing the following parameters:
   *
   * - `uniqueIdentificationNumber`:
   *
   * - `serialNumber`:
   *
   * - `referenceId`:
   *
   * - `date`:
   *
   * - `category`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return The results are returned.
   */
  SearchForClaimFileResponse(params: DriversLicenseService.SearchForClaimFileParams): __Observable<__StrictHttpResponse<ValidityForClaimViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.uniqueIdentificationNumber != null) __params = __params.set('uniqueIdentificationNumber', params.uniqueIdentificationNumber.toString());
    if (params.serialNumber != null) __params = __params.set('serialNumber', params.serialNumber.toString());
    if (params.referenceId != null) __params = __params.set('referenceId', params.referenceId.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    if (params.category != null) __params = __params.set('category', params.category.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.XCorrelationID != null) __headers = __headers.set('X-Correlation-ID', params.XCorrelationID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/driverslicense/searchforclaimfile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ValidityForClaimViewModel>;
      })
    );
  }
  /**
   * @param params The `DriversLicenseService.SearchForClaimFileParams` containing the following parameters:
   *
   * - `uniqueIdentificationNumber`:
   *
   * - `serialNumber`:
   *
   * - `referenceId`:
   *
   * - `date`:
   *
   * - `category`:
   *
   * - `api-version`: The requested API version
   *
   * - `X-Correlation-ID`: Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
   *
   * - `Authorization`: Api key
   *
   * @return The results are returned.
   */
  SearchForClaimFile(params: DriversLicenseService.SearchForClaimFileParams): __Observable<ValidityForClaimViewModel> {
    return this.SearchForClaimFileResponse(params).pipe(
      __map(_r => _r.body as ValidityForClaimViewModel)
    );
  }
}

module DriversLicenseService {

  /**
   * Parameters for SearchPolicy
   */
  export interface SearchPolicyParams {
    uniqueIdentificationNumber?: string;
    serialNumber?: string;
    referenceId?: string;
    date?: string;
    category?: string;

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }

  /**
   * Parameters for SearchForClaimFile
   */
  export interface SearchForClaimFileParams {
    uniqueIdentificationNumber?: string;
    serialNumber?: string;
    referenceId?: string;
    date?: string;
    category?: string;

    /**
     * The requested API version
     */
    apiVersion?: string;

    /**
     * Used to uniquely identify the HTTP request. This ID is used to correlate the HTTP request between a client and server.
     */
    XCorrelationID?: string;

    /**
     * Api key
     */
    Authorization?: string;
  }
}

export { DriversLicenseService }
