import { GlobalErrorHandler } from "./global-error-handler";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ErrorHandler, Injector } from "@angular/core";

import { AppComponent } from "./app.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ApiModule } from "./data/api/api.module";
import { CommonModule } from "@angular/common";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DashboardPageModule } from "./pages/dashboard/dashboard-page.module";
import { SearchDriversLicenceModule } from './pages/search-drivers-licence-page/search-drivers-licence.module';
import { RouterModule } from "@angular/router";
import { ROUTES } from "./app.routes";
import { LayoutModule } from "./layout/layout.module";
import { BootstrapModule } from "./common/bootstrap.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NotFoundModule } from "./pages/not-found/not-found.module";
import { MatListModule } from "@angular/material/list";
import { ToastrModule } from "ngx-toastr";
import { StorageModule } from "@ngx-pwa/local-storage";
import { HttpConfigInterceptor } from "./common/http-authorization.interceptor";
import { ServiceLocator } from "./common/service.locator";

import { ComponentsModule } from "src/app/components/components.module";
import { MatInputModule } from "@angular/material/input";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    CommonModule,
    BootstrapModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    ToastrModule.forRoot(),
    ApiModule.forRoot({ rootUrl: "/api" }),
    RouterModule.forRoot(ROUTES, { onSameUrlNavigation: "reload" }),
    LayoutModule,
    DashboardPageModule,
    SearchDriversLicenceModule,
    NotFoundModule,
    MatListModule,
    ComponentsModule,
    MatInputModule,
    StorageModule.forRoot({ IDBNoWrap: true })
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    ServiceLocator.injector = this.injector;
  }
}
