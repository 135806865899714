import { NgModule } from "@angular/core";
import { DashboardPageComponent } from "./dashboard-page/dashboard-page.component";
import { ComponentsModule } from "src/app/components/components.module";

@NgModule({
  declarations: [DashboardPageComponent],
  imports: [
  ],
  exports: [DashboardPageComponent, ComponentsModule]
})
export class DashboardPageModule {}
