import { Injectable } from "@angular/core";
@Injectable({
    providedIn: "root"
})
export class LoggedUserService {
    private isUserAdmin?: boolean;
    private vdCanUserSearcPolicy?: boolean = true;
    private vdCanUserSearcForClaimFile?: boolean = true;

    constructor() { }
    setIsAdmin(isAdminParam?: boolean) {
        this.isUserAdmin = isAdminParam;
    }

    setVdCanSearcPolicy(vdCanSearcPolicy?: boolean) {
        this.vdCanUserSearcPolicy = vdCanSearcPolicy;
    }
    setCanSearcForClaimFile(vdCanSearcForClaimFile?: boolean) {
        return this.vdCanUserSearcForClaimFile = vdCanSearcForClaimFile;
    }

    isAdmin() {
        return this.isUserAdmin;
    }

    vdCanSearcPolicy() {
        return this.vdCanUserSearcPolicy;
    }

    vdCanSearcForClaimFile() {
        return this.vdCanUserSearcForClaimFile;
    }
}
