import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { pairwise, tap, filter, map } from "rxjs/operators";
import { BackButtonStateService } from "./common/back-button-state.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  title = "szz-ui-app";

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private backButtonStateService: BackButtonStateService
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data["showBack"]) {
              return child.snapshot.data["showBack"];
            } else {
              return null;
            }
          }
          return null;
        }),
        tap(x => {
          this.backButtonStateService.updateButtonState(x || false);
        })
      )
      .subscribe();
  }
}
